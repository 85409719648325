import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Content from './Content'
import Separator from './Separator'

// animation setup
// ==========================================================================================
const revealFrames = keyframes`
0% {
  opacity:0;
}
100% {
  opacity: 1;
}`

// styles
// ==========================================================================================
const Wrapper = styled.div`
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.gray10};
  border-radius: 5px 30px 30px 30px;
  animation: ${revealFrames} 0.6s linear;
  animation-delay: 0.4s;
  animation-fill-mode: both;
`

// component
// ==========================================================================================
const Intro = ({ intro }) => {
  if (!intro) {
    return null
  }

  return (
    <Content>
      <Separator small />
      <Row>
        <Col xs={8} xsOffset={1} md={7}>
          <Wrapper>
            <h4>{intro}</h4>
          </Wrapper>
        </Col>
      </Row>
    </Content>
  )
}

export default Intro
