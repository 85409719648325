import React, { useRef, useCallback, useEffect, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import ReactPlayer from 'react-player'

import { StateContext } from '../context/GlobalStateContext'
import Content from './Content'
import Intro from './Intro'
import ImageComponent from './Image'
import Wysiwyg from './Wysiwyg'
import StaggerHeading from './StaggerHeading'
import { breakpoints } from '../styles/vars'

// animation setup
// ==========================================================================================
const revealFrames = keyframes`
0% {
  opacity:0;
}
100% {
  opacity: 1;
}`

// styles
// ==========================================================================================
const Wrapper = styled.div`
  position: relative;
  height: ${({ hasImage }) => (hasImage ? '750px' : 'auto')};
  margin-top: ${({ hasImage }) => (hasImage ? 'auto' : '104px')};
  font-family: ${({ theme }) => theme.fonts.feature};
  color: ${({ hasImage, theme }) => (hasImage ? '#fff' : theme.colors.navy)};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${({ hasImage }) => (hasImage ? 'auto' : '104px')};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${({ hasImage }) => (hasImage ? 'auto' : '216px')};
  }
`
const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.gray10};
  z-index: -1;

  &::after {
    /* hide overlay on video */
    ${({ hasVideo }) => (hasVideo ? 'content: initial;' : 'content: "";')}
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.primary};
    opacity: 0.3;
    z-index: 5;
    mix-blend-mode: multiply;
  }
  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
const Image = styled(ImageComponent)`
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    transition: opacity 0.3s, visibility 0.3s;
  }
  @media (min-width: 2500px) {
    visibility: visible;
    opacity: 1;
  }
`
const Video = styled(ReactPlayer)`
  position: relative;
  width: 100%;
  max-width: 2500px;
  z-index: 1;

  @media (min-width: 2500px) {
    display: none;
  }
`
const FullHeight = styled(Col)`
  height: 750px;
`
const Label = styled.h5`
  color: ${({ hasImage, theme }) => (hasImage ? '#fff' : theme.colors.primary)};
  animation: ${revealFrames} 0.6s linear;
  animation-delay: 0.4s;
  animation-fill-mode: both;
`
const HeadingWrapper = styled.div`
  ${StaggerHeading} {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
      opacity: ${({ hide }) => (hide ? 0 : 1)};
      transition: opacity 0.3s, visibility 0.3s;
    }
    @media (min-width: 2500px) {
      visibility: visible;
      opacity: 1;
    }
  }
`
const WysiwygWrapper = styled.div`
  animation: ${revealFrames} 0.6s linear;
  animation-delay: 0.4s;
  animation-fill-mode: both;
`
// component
// ==========================================================================================
const Hero = ({
  heroLabel,
  heroBody,
  heroImage,
  heroImageSharp,
  heroVideo,
  triggerVideo,
  ...data
}) => {
  // const [hasVideoEnded, setHasVideoEnded] = useState(false)
  const hasImage = Boolean(heroImage || heroVideo)
  const ref = useRef(null)
  const [, dispatch] = useContext(StateContext)
  const options = { threshold: [0, 0.9] }
  const intersectionCallback = useCallback(entries => {
    if (hasImage && window.innerWidth < breakpoints.md) {
      entries.forEach(entry => {
        dispatch({
          type: 'changeLogoColor',
          updates: { isLogoDark: entry.intersectionRatio === 0 },
        })
      })
    }
  })
  useEffect(() => {
    let observer = null
    if (hasImage) {
      observer = new IntersectionObserver(intersectionCallback, options)
      observer.observe(ref.current)
    }

    return () => {
      if (observer) {
        observer.unobserve(ref.current)
      }
    }
  }, [hasImage])

  return (
    <>
      <Wrapper hasImage={hasImage} ref={ref}>
        {hasImage && (
          <ImageWrapper hasVideo={!!heroVideo}>
            {heroVideo && (
              <Video
                url={heroVideo.url}
                playing={triggerVideo}
                controls={false}
                muted
                loop
                width="100%"
                height="100%"
              />
            )}
            <Image image={heroImage} {...heroImageSharp} hide={triggerVideo} />
          </ImageWrapper>
        )}
        <Content>
          <Row middle="xs">
            <Col xs={8} xsOffset={1} md={7}>
              {heroLabel && <Label hasImage={hasImage}>{heroLabel}</Label>}
              <HeadingWrapper hide={triggerVideo} aria-hidden={triggerVideo}>
                <StaggerHeading {...data} />
              </HeadingWrapper>
              <WysiwygWrapper>
                <Wysiwyg content={heroBody} />
              </WysiwygWrapper>
            </Col>
            {hasImage && <FullHeight xs={1} />}
          </Row>
        </Content>
      </Wrapper>
      <Intro {...data} />
    </>
  )
}

export default Hero
