import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { renderer } from '../utils/prismic'
import listMarker from '../images/list-marker.svg'

// styles
// ==========================================================================================
const Wrapper = styled.div`
  /* background: ${({ theme }) => theme.colors.gray50}; */

  *:last-child {
    margin-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.colors.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
  }
  li {
    background: url(${listMarker}) 0 4px no-repeat;
    padding-left: 32px;
    margin-bottom: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    ul {
      margin-top: 32px;
    }
    li {
      margin-bottom: 32px;
    }
  }

`

// component
// ==========================================================================================
const Wysiwyg = ({ meta, content }) => {
  if (!content) {
    return null
  }

  return (
    <Wrapper>
      <div>
        {renderer(content)}

        {meta?.uid === 'privacy' && (
          <div>
            <a
              href="https://www.iubenda.com/privacy-policy/56298094"
              className="iubenda-white no-brand iubenda-embed iub-legal-only iub-no-markup iub-body-embed"
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/56298094/cookie-policy"
              className="iubenda-white no-brand iubenda-embed iub-no-markup iub-body-embed"
              title="Cookie Policy"
            >
              Cookie Policy
            </a>
            <Helmet>
              <script async src="https://cdn.iubenda.com/iubenda.js" />
            </Helmet>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default Wysiwyg
